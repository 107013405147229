import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Game = (props) => {

  const { isAuth, iframeUrl, mobileScreenHeight, mobileScreenWidth, userMobileNumber, handleInactivity, isLoading, setShowSkipBtn, userSelectedLanguage, useElevenLabs, rebootSignallingServerRef } = props

  const navigate = useNavigate()

  useEffect(() => {
    // console.log("first")
    if (!isAuth) {
      navigate('/')
    }
  }, [])

  const [connectivityStatus, setConnectivityStatus] = useState("idle")
  const iframeLoaded = useRef(false)
  const iframeRef = useRef(null)

  const sendMessageToIframe = () => {
    console.warn("sending mobile to iframe", userMobileNumber.current)
    const iframeContentWindow = iframeRef.current.contentWindow;
    const message = {
      type: 'mobileNumber',
      value: userMobileNumber.current
    };
    iframeContentWindow.postMessage(message, iframeUrl);
    // iframeContentWindow.postMessage(message, 'http://localhost/');
  };

  const sendLangToIframe = () => {
    console.warn("sending lang to iframe", userSelectedLanguage.current)
    const iframeContentWindow = iframeRef.current.contentWindow;
    const message = {
      type: 'language',
      value: userSelectedLanguage.current
    };
    iframeContentWindow.postMessage(message, iframeUrl);
    // iframeContentWindow.postMessage(message, 'http://localhost/');
  };

  const sendVoiceIdToIframe = () => {
    console.warn("sending use custom voice to iframe", useElevenLabs.current)
    const iframeContentWindow = iframeRef.current.contentWindow;
    const message = {
      type: 'useElevenLabs',
      value: useElevenLabs.current
    };
    iframeContentWindow.postMessage(message, iframeUrl);
    // iframeContentWindow.postMessage(message, 'http://localhost/');
  };

  const handleMessageFromIframe = (event) => {
    console.warn('Message received from iframe:', event.data);

    if (event.data !== undefined && event.data !== null && event.data.type === 'isInactive') {
      // localStorage.setItem('isInactive', true);
      handleInactivity(true)
      console.warn('user inactive received from iframe');
    }

    if (event.data !== undefined && event.data !== null && event.data.type === 'userLanguage') {
      // localStorage.setItem('isInactive', true);
      // setUserSelectedLanguage(event.data.value)
      userSelectedLanguage.current = event.data.value
      localStorage.setItem("userSelectedLanguage", event.data.value)
      sendLangToIframe()
      console.warn('language update received from iframe');
    }

    if (event.data !== undefined && event.data !== null && event.data.type === 'status') {
      setConnectivityStatus(event.data.value)
      console.warn("connectivity status received from iframe", event.data.type, event.data.value);
    }
  };

  window.addEventListener('message', handleMessageFromIframe);


  const timeoutRef = useRef()
  const iframeLoadInterval = useRef()
  // const disconnectionTimer = useRef()
  const rebootMsgSent = useRef(false)

  const called = useRef(false)
  const sendUserDetailsInterval = useRef()

  useEffect(() => {
    if (called.current === false) {
      setTimeout(() => {
        sendUserDetailsInterval.current = setInterval(() => {
          console.error("Trying to send user details to iframe...")
          if (iframeLoaded.current) {
            clearInterval(sendUserDetailsInterval.current)
            sendMessageToIframe()
            sendLangToIframe()
            sendVoiceIdToIframe()
            console.warn("User details sent")
          }
        }, 1000);
      }, 5000);
      called.current = true
    }
  }, [])

  const onLoadMethod = () => {
    console.log("iframe is loaded")
    iframeLoaded.current = true
  }

  useEffect(() => {
    if (connectivityStatus === "connected") {
      console.warn("status", connectivityStatus)
      if (isLoading) {
        console.warn("Should show skip button after 7secs without disconnection and iframe loaded")
        clearTimeout(timeoutRef.current)
        clearInterval(iframeLoadInterval.current)
        iframeLoadInterval.current = setInterval(() => {
          console.log("checking iframe loading status: ", iframeLoaded.current)
          if (iframeLoaded.current) {
            clearInterval(iframeLoadInterval.current)
            timeoutRef.current = setTimeout(() => {
              setShowSkipBtn(true)
            }, 7000);
          }
        }, 1000);
      }
    }
    else if (connectivityStatus === "idle") {
      console.warn("status", connectivityStatus)
      setShowSkipBtn(false)
      clearTimeout(timeoutRef.current)
      clearInterval(iframeLoadInterval.current)

      if (rebootMsgSent.current === false) {
        console.log("will send reboot signaling server after min: IDLE")
        timeoutRef.current = setTimeout(() => {
          rebootMsgSent.current = true
          rebootSignallingServerRef.current()
        }, 90000);
      }

    }
    else if (connectivityStatus === "disconnected") {
      console.warn("status", connectivityStatus)
      setShowSkipBtn(false)
      clearTimeout(timeoutRef.current)
      clearInterval(iframeLoadInterval.current)

      if (rebootMsgSent.current === false) {
        console.log("will send reboot signaling server after 30sec: DISCONNECT")
        timeoutRef.current = setTimeout(() => {
          rebootMsgSent.current = true
          rebootSignallingServerRef.current()
        }, 30000);
      }

    }
  }, [connectivityStatus])


  useEffect(() => {
    const handleResize = () => {
      if (iframeRef.current) {
        iframeRef.current.style.width = `${window.innerWidth}px`;
        iframeRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    // Set initial heights when the component mounts
    handleResize();

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <iframe
      ref={iframeRef}
      title="Nine Reflex"
      src={iframeUrl}
      // src={"http://localhost/"}
      allow="microphone"
      onLoad={onLoadMethod}
      style={{
        width: `${mobileScreenWidth.current}px`,
        height: `${mobileScreenHeight.current}px`,
        border: "none",
        zIndex: "999",
        visibility: isLoading ? "hidden" : "visible"
      }}
    />
  )
}

export default Game
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import PuffLoader from 'react-spinners/PuffLoader';

import { BiSolidVolumeFull } from "react-icons/bi";
import { BiSolidVolumeMute } from "react-icons/bi";


const Loading = ({ isQueued, userSelectedLanguage, useElevenLabs, showSkipBtn, isAuth }) => {

  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuth) {
      navigate('/')
    }
  }, [])
  

  console.warn("playing video in this language:", userSelectedLanguage)

  const mobileVideoUrl =
    userSelectedLanguage.current === "हिं" ?
      `https://xrv-xrc.s3.ap-south-1.amazonaws.com/NineReflex/Resources/${useElevenLabs.current ? "ElevenLabsAudio/" : "GoogleCloudAudio/"}urbanedgeloadingmobileHI.mp4` :
      userSelectedLanguage.current === "MA" ?
        `https://xrv-xrc.s3.ap-south-1.amazonaws.com/NineReflex/Resources/${useElevenLabs.current ? "ElevenLabsAudio/" : "GoogleCloudAudio/"}urbanedgeloadingmobileMA.mp4`
        :
        `https://xrv-xrc.s3.ap-south-1.amazonaws.com/NineReflex/Resources/${useElevenLabs.current ? "ElevenLabsAudio/" : "GoogleCloudAudio/"}urbanedgeloadingmobileEN.mp4`
  const desktopVideoUrl =
    userSelectedLanguage.current === "हिं" ?
      `https://dzqtlggd9602l.cloudfront.net/Resources/${useElevenLabs.current ? "ElevenLabsAudio/" : "GoogleCloudAudio/"}urbanedgeloadingdesktopHI.mp4` :
      userSelectedLanguage.current === "MA" ?
        `https://dzqtlggd9602l.cloudfront.net/Resources/${useElevenLabs.current ? "ElevenLabsAudio/" : "GoogleCloudAudio/"}urbanedgeloadingdesktopMA.mp4`
        :
        `https://dzqtlggd9602l.cloudfront.net/Resources/${useElevenLabs.current ? "ElevenLabsAudio/" : "GoogleCloudAudio/"}urbanedgeloadingdesktopEN.mp4`

  const [videoKey, setVideoKey] = useState(false)

  // Determine which video to use based on screen size
  // const videoSource = useRef(window.innerWidth > window.innerHeight ? desktopVideoUrl : mobileVideoUrl);
  const videoSource = useRef(desktopVideoUrl)
  const [, forceUpdate] = useState();

  const updateVideoSource = () => {
    // const newVideoSource = window.innerWidth > window.innerHeight ? desktopVideoUrl : mobileVideoUrl;
    // if (videoSource.current !== newVideoSource) {
    //   setIsMuted(true)
    //   setVideoKey((prev) => !prev)
    //   videoSource.current = newVideoSource;
    //   forceUpdate(Math.random()); // Trigger a re-render
    // }
    const loadingVideo = document.getElementById("loading-video")
    if (window.innerWidth > window.innerHeight) {
      loadingVideo.style.objectFit = "cover"
      loadingVideo.style.objectPosition = "center bottom"
    }
    else {
      loadingVideo.style.objectFit = "contain"
      loadingVideo.style.objectPosition = "center center"
    }
  };

  const updateLoadingScreenHeight = () => {
    const loadingScreen = document.querySelector('.loading-screen');
    if (loadingScreen) {
      loadingScreen.style.height = window.innerHeight + 'px';
    }
  };

  useEffect(() => {
    // updateLoadingScreenHeight();
    // updateVideoSource();
    // window.addEventListener('resize', updateLoadingScreenHeight);
    window.addEventListener('resize', updateVideoSource);

    // Listen for changes in the visual viewport size
    // const handleResize = () => {
    //   updateLoadingScreenHeight();
    // };
    // window.visualViewport.addEventListener('resize', handleResize);

    return () => {
      // window.removeEventListener('resize', updateLoadingScreenHeight);
      window.removeEventListener('resize', updateVideoSource);
      // window.visualViewport.removeEventListener('resize', handleResize);
    };
  }, []);

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const [isMuted, setIsMuted] = useState(isIOS());

  const handleVolume = () => {
    console.log("isMuted", isMuted)
    setIsMuted((prev) => !prev)
  };

  return (
    <>
      <button className='volume-btn' onClick={handleVolume}
        style={{
          position: "absolute",
          zIndex: "10000001",
        }}>
        {
          isMuted ?
            <BiSolidVolumeMute color='rgba(255, 255, 255, 0.9)' size={15} />
            :
            <BiSolidVolumeFull color='rgba(255, 255, 255, 0.9)' size={15} />
        }
      </button>
      <div className='overlay-alt-2'></div>
      <div className='load-screen-logo-loading' style={{ position: "absolute", left: "50%", transform: "translateX(-50%)", marginTop: "1.5rem" }}>
        <img src='https://xrv-xrc.s3.ap-south-1.amazonaws.com/NineReflex/Resources/REFLEX_PROJECT_Logo_min.png' alt='Reflex reality'></img>
      </div>
      <div className='loading-screen' style={{ position: "relative", display: 'flex', justifyContent: "center", zIndex: "10000" }}>
        <video
          key={videoKey}
          id='loading-video'
          autoPlay
          // loop
          muted={isMuted}
          playsInline // Play inline on iOS
          style={{ position: "absolute", width: "100%", height: "100%", objectFit: window.innerWidth > window.innerHeight ? "cover" : "contain", objectPosition: window.innerWidth > window.innerHeight ? "center bottom" : "center center" }}
        >
          <source src={videoSource.current} type="video/mp4" />
        </video>
        {
          showSkipBtn
            ?
            ""
            :
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", zIndex: 1, flexDirection: "column", marginTop: "-3rem" }}>
              {/* {isQueued &&
                <div className='ff-m fs-lg' style={{ color: "white", position: "absolute", bottom: "5%", textAlign: "center", width: "100vw" }}>You are in a queue, please  wait ...</div>
              } */}
              <PuffLoader
                color="white"
                size={window.innerWidth > 768 ? 100 : 70}
              />
              <div className='ff-m fs-m exp-loading-text' style={{ color: "white", marginLeft: "0.75rem" }}>{isQueued ? "You are in a queue, please  wait" : "Preparing your experience"}
              </div>
            </div>
        }
      </div>
    </>
  );
};


export default Loading;

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function PrivateRoute({ element, isAuth }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate('/'); // Redirect to home if not authenticated
    }
  }, [isAuth, navigate]);

  return isAuth ? element : null; // Render the element only if authenticated
}

export default PrivateRoute;

import React, { useState, useEffect, useRef } from 'react'
import io from "socket.io-client";
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import PrivateRoute from '../PrivateRoute';

import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios'

import Welcome from './Welcome'
import Auth from './Auth'
import Loading from './Loading';
import Game from './Game';

import PuffLoader from 'react-spinners/PuffLoader';

const Start = () => {

  const url = "https://api.urbanedge.xrvizion.com";
  // const url = "http://localhost:5000"

  const navigate = useNavigate();

  const useElevenLabs = useRef(false)
  const [showOtp, setShowOtp] = useState(false)

  const [serverUrl, setServerUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true)
  const [formSubmitted, setIsFormSubmitted] = useState(false)
  const [invokeAuth, setInvokeAuth] = useState(false)
  const [gameUrl, setGameUrl] = useState("")
  const mobileScreenHeight = useRef(0)
  const mobileScreenWidth = useRef(0)

  const [firstSocket, setFirstSocket] = useState(null);
  const [isQueued, setIsQueued] = useState(false)
  const [showSkipBtn, setShowSkipBtn] = useState(false)
  const closeGame = useRef();
  const rebootSignallingServerRef = useRef()
  const userMobileNumber = useRef(0)
  // const [userSelectedLanguage, setUserSelectedLanguage] = useState("EN")
  const userSelectedLanguage = useRef("EN")

  const authRequestSent = useRef(false)
  const [isAuth, setIsAuth] = useState(false)
  const [checkingStatus, setCheckingStatus] = useState(false)
  const skipBtnTimeout = useRef()

  const showWarningMsg = useRef(true)

  const isAuthenticated = () => {
    return isAuth; // or your logic to determine authentication
  }


  // LOGOUT
  const handleInactivity = async (fromIframe = false) => {
    // if (fromIframe) {
    //   toast.error('Received inactive from the iframe', {
    //     position: window.innerWidth > 768 ? "top-right" : "bottom-center",
    //     className: window.innerWidth < 768 && "fs-sm",
    //     style: {
    //       background: 'radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)',
    //     },
    //   })
    // }

    if (showWarningMsg.current) {
      showWarningMsg.current = false
      toast('Uh-oh! Try again.', {
        icon: '⚠️',
        position: window.innerWidth > 768 ? "top-right" : "bottom-center",
        className: window.innerWidth < 768 && "fs-sm",
        style: {
          background: 'radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)',
        },
      })

      setTimeout(() => {
        showWarningMsg.current = true
      }, 5000);
    }


    // const isInactive = localStorage.getItem('isInactive');
    // console.log(isInactive)
    // if (isInactive) {
    console.warn('logging out user...');
    setIsFormSubmitted(false)
    setInvokeAuth(false)
    setServerUrl("")
    setGameUrl("")
    setIsLoading(true)
    showExpText.current = true
    setShowSkipBtn(false)
    localStorage.setItem("isInactive", false)
    clearTimeout(skipBtnTimeout.current)
    // console.log(closeGame.current)

    try {
      console.warn("log out time sent", userMobileNumber.current)

      const response = await axios.post(url + '/user/logouttime', {
        mobileNumber: userMobileNumber.current,
      });
      console.log('response of log out time:', response.data);
      closeGame.current()
    }
    catch (error) {
      console.error('error sending logout time:', error);
      closeGame.current()
    }
    // }
  };


  // ALREADY LOGGED IN
  const checkStatus = async (mobileNumber) => {
    console.warn("checking status")
    authRequestSent.current = true;
    try {
      const response = await axios.post(
        url + "/user/authorize",
        { mobileNumber: mobileNumber }
        , {
          withCredentials: true
        }
      );
      if (response.data.isUserAuthorized) {
        setIsAuth(true)
        userMobileNumber.current = localStorage.getItem('mobile')
        setIsFormSubmitted(true)
        const lsLangauge = localStorage.getItem("userSelectedLanguage")
        console.warn("lsLanguage", lsLangauge)
        userSelectedLanguage.current = lsLangauge || "EN"
        setCheckingStatus(false)
        return true
      }
      else {
        setIsAuth(false)
        setInvokeAuth(true)
        setCheckingStatus(false)
        return false
      }
    } catch (error) {
      // console.error(error);
      setIsAuth(false)
      setCheckingStatus(false)
      setInvokeAuth(true)
      console.warn("user not authorized")
      return false
    }
  }

  const initiateAuthorization = async () => {
    // const mobileNumberLS = localStorage.getItem('mobile')

    setCheckingStatus(true)

    // if (mobileNumberLS) {
      // console.log("checking status")
      // const status = await checkStatus(mobileNumberLS)
    //   if (status) {
    //     navigate('/video');
    //   }
    //   else {
    //     navigate('/login');
    //   }
    // }

    // if (!mobileNumberLS) {
      setCheckingStatus(false)
      setInvokeAuth(true)
      navigate('/login');
    // }
  }


  useEffect(() => {
    // Get and store mobile screen dimensions
    mobileScreenHeight.current = window.innerHeight;
    mobileScreenWidth.current = window.innerWidth;
  
    console.log("screen size: ", mobileScreenWidth.current, mobileScreenHeight.current);
  
    // Clear any existing tracker_code from localStorage
    localStorage.removeItem('tracker_code');
  
    const params = new URLSearchParams(window.location.search);
  
    // Extract the tracker_code from the URL
    const code = params.get('tracker_code');
  
    if (code) {
      // Store the new tracker code in localStorage
      localStorage.setItem('tracker_code', code);
    }
  }, []);


  // SOCKET CONNECTIONS
  useEffect(() => {
    if (formSubmitted === true) {
      console.log("formSubmitted", formSubmitted)
      const socket = io.connect(url);
      setFirstSocket(socket);
      let pingInterval

      const handleConnect = () => {
        console.log("websocket connected", socket.id);
        pingInterval = setInterval(sendPingMessage, 10000)
      };

      const handleDisconnect = () => {
        handleInactivity()
      }

      // const handleServerStarted = (serverLink) => {
      //   console.log("server started", serverLink);
      //   setServerUrl(serverLink);
      // };

      const handlePortAssigned = (fullURL) => {
        console.log("port assigned", fullURL);
        setServerUrl(fullURL);
      };

      const handleMaxInstanceLimitReached = () => {
        console.log("max instance limit reached received from the server");
        setIsQueued(true)
        // handleRetry()
      };

      const handleRetry = () => {
        setTimeout(() => {
          // toast.error('Received retry from the server', {
          //   position: window.innerWidth > 768 ? "top-right" : "bottom-center",
          //   className: window.innerWidth < 768 && "fs-sm",
          //   style: {
          //     background: 'radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)',
          //   },
          // })
          console.log("retrying received from the server");
          socket.disconnect();
          socket.connect();
        }, 3000);
      };

      const sendPingMessage = () => {
        socket.emit("ping", userMobileNumber.current)
        console.log("ping sent to the server", userMobileNumber.current)
      };

      closeGame.current = () => {
        // console.log("called")
        socket.disconnect();
        navigate('/')
      }

      rebootSignallingServerRef.current = () => {
        // console.log("not sending rebootsignalling server to the server, just logout in the frontend")
        console.log("sending rebootsignalling server to the server")
        socket.emit('rebootSignallingServer')
        // handleInactivity()
      }

      socket.on("connect", handleConnect);
      // socket.on("serverStarted", handleServerStarted);
      // socket.on("MaxInstanceLimitReached", handleMaxInstanceLimitReached);
      socket.on("queue", handleMaxInstanceLimitReached);
      socket.on("retry", handleRetry);
      socket.on("portAssigned", handlePortAssigned)
      socket.on("queueStatus", handleRetry)
      socket.on("disconnect", () => {
        toast('Uh-oh! Try again.', {
          icon: '⚠️',
          position: window.innerWidth > 768 ? "top-right" : "bottom-center",
          className: window.innerWidth < 768 && "fs-sm",
          style: {
            background: 'radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)',
          },
        })
        console.warn("received disconnect from the server")
        handleDisconnect()
      });
      socket.on("serverRebooted", () => {
        toast('Uh-oh! Try again.', {
          icon: '⚠️',
          position: window.innerWidth > 768 ? "top-right" : "bottom-center",
          className: window.innerWidth < 768 && "fs-sm",
          style: {
            background: 'radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)',
          },
        })
        console.warn("received server rebooted from the server")
        handleDisconnect()
      });

      // // Add an effect to handle disconnection of the first WebSocket
      // if (firstSocket) {
      //   firstSocket.on("disconnect", () => {
      //     console.log("First WebSocket disconnected.");
      //     if (secondSocket && secondSocket.connected) {
      //       console.log("Disconnecting second WebSocket.");
      //       secondSocket.disconnect();
      //     }
      //   });
      // }

      return () => {
        clearInterval(pingInterval);
        socket.disconnect(); // Cleanup function: disconnect WebSocket
      };
    }
  }, [formSubmitted]);


  // CONNECTIING TO THE GAME
  useEffect(() => {
    let isMounted = true; // To track component unmounting
    let timerId;

    const checkUrlAccessibility = async (url) => {
      console.warn("checking url accessibility", url)
      try {
        const response = await fetch(url);
        console.warn("url response", response)
        if (response.ok) {
          // URL is accessible
          console.log("url is accessible");
          if (isMounted) {
            setGameUrl(url);
            setTimeout(() => {
              // setIsLoading(false);
              // showSkipBtn.current = true
              setIsQueued(false)
              // setShowSkipBtn(true)
              // console.log("SKIP BUTTON")
            }, 5000);
          }
        } else {
          console.log("url is not accessible. retrying...");
          // Retry after some delay
          timerId = setTimeout(() => checkUrlAccessibility(url), 2000); // Retry after 2 seconds
        }
      } catch (error) {
        console.error("error accessing url:", error);
        // Retry after some delay
        timerId = setTimeout(() => checkUrlAccessibility(url), 2000); // Retry after 2 seconds
      }
    };

    if (serverUrl) {
      // const urlToCheck = serverUrl + ":5001";
      const urlToCheck = serverUrl;
      checkUrlAccessibility(urlToCheck);
    }

    return () => {
      isMounted = false; // Component is unmounting, clear the flag
      clearTimeout(timerId); // Clear any pending retries
    };
  }, [serverUrl])


  // SOCKET DISCONNECTION
  useEffect(() => {
    // Add event listener for beforeunload
    const handleBeforeUnload = () => {
      if (firstSocket) {
        // localStorage.setItem("msg1", "Cleaning up first WebSocket before page unload...");
        firstSocket.close();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [firstSocket]);


  const showExpText = useRef(true)

  // SUCCESS MESSAGE
  // const userVerfied = useRef(false)
  // useEffect(() => {
  //   if (formSubmitted && !userVerfied.current) {
  //     toast.success('Verification successful', {
  //       position: window.innerWidth > 768 ? "top-right" : "bottom-center",
  //       className: window.innerWidth < 768 && "fs-sm",
  //       style: {
  //         background: 'radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)',
  //       },
  //     })
  //     userVerfied.current = true
  //   }
  // }, [formSubmitted])


  // ENTER GAME
  const enterGame = () => {
    clearTimeout(skipBtnTimeout.current)
    setIsLoading(false)
    setShowSkipBtn(false)
    showExpText.current = false
    navigate('/walkthrough')
  }

  useEffect(() => {
    if (showSkipBtn) {
      console.log("inactivity loading timer started")
      skipBtnTimeout.current = setTimeout(() => {
        // toast.error('Inactivity detected in loading component', {
        //   position: window.innerWidth > 768 ? "top-right" : "bottom-center",
        //   className: window.innerWidth < 768 && "fs-sm",
        //   style: {
        //     background: 'radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)',
        //   },
        // })
        handleInactivity()
        console.warn("inactivity detected in loading component")
      }, 120000);
    }
  }, [showSkipBtn])


  return (
    <>
      {
        checkingStatus ? (
          <div className='loading-screen' style={{ position: "relative", display: 'flex', justifyContent: "center", zIndex: "10000" }}>
            <div style={{ zIndex: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "-3rem", width: "100%", height: "100%" }}>
              <PuffLoader
                color="white"
                size={window.innerWidth > 768 ? 100 : 70}
              />
            </div>
          </div>
        ) : (
          <>
            <div><Toaster /></div>
            <div className='container'>
              <Routes>
                <Route
                  path='/'
                  element={
                    <Welcome userSelectedLanguage={userSelectedLanguage} isAuth={isAuth} setInvokeAuth={setInvokeAuth} setIsFormSubmitted={setIsFormSubmitted} userMobileNumber={userMobileNumber} initiateAuthorization={initiateAuthorization} />
                  }
                />

                <Route
                  path='/login'
                  element={
                    <Auth showOtp={showOtp} setShowOtp={setShowOtp} setIsAuth={setIsAuth} userSelectedLanguage={userSelectedLanguage} setIsFormSubmitted={setIsFormSubmitted} mobileScreenHeight={mobileScreenHeight} mobileScreenWidth={mobileScreenWidth} userMobileNumber={userMobileNumber} />
                  }
                />
                {/* 
                <Route
                  path='/verify'
                  element={
                    <Auth showOtp={showOtp} setShowOtp={setShowOtp} setIsAuth={setIsAuth} userSelectedLanguage={userSelectedLanguage} setIsFormSubmitted={setIsFormSubmitted} mobileScreenHeight={mobileScreenHeight} mobileScreenWidth={mobileScreenWidth} userMobileNumber={userMobileNumber} />
                  }
                /> */}

                <Route
                  path='/video'
                  element={
                    <Loading isAuth={isAuth} isQueued={isQueued} userSelectedLanguage={userSelectedLanguage} useElevenLabs={useElevenLabs} showSkipBtn={showSkipBtn} />
                  }
                />

                <Route
                  path='/walkthrough'
                  element={
                    <PrivateRoute
                      isAuth={isAuth}
                      element={<div>{/* Other components for walkthrough can be added here */}</div>}
                    />
                  }
                />

                <Route
                  path="*"
                  element={<Navigate to="/" />} // Redirect to home if no matching route
                />
              </Routes>

              {/* Game Component Rendered Once */}
              {(window.location.pathname === '/video' || window.location.pathname === '/walkthrough') && (
                <>
                  {(formSubmitted && gameUrl.length > 0) && (
                    <Game
                      useElevenLabs={useElevenLabs}
                      userSelectedLanguage={userSelectedLanguage}
                      iframeUrl={gameUrl}
                      mobileScreenHeight={mobileScreenHeight}
                      mobileScreenWidth={mobileScreenWidth}
                      userMobileNumber={userMobileNumber}
                      handleInactivity={handleInactivity}
                      isLoading={isLoading}
                      setShowSkipBtn={setShowSkipBtn}
                      rebootSignallingServerRef={rebootSignallingServerRef}
                      isAuth={isAuth}
                    />
                  )}

                  {(formSubmitted && showSkipBtn) && (
                    <button className='start-button skip-btn ff-sb fs-sm' onClick={enterGame}>Skip</button>
                  )}

                  {(formSubmitted && !showSkipBtn && showExpText.current) && (
                    <div className='ff-m fs-m exp-loading-text-mobile' style={{ color: "white", marginLeft: "0.25rem" }}>
                      {isQueued ? "You are in a queue, please wait" : "Preparing your experience"}
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )
      }
    </>
  )
}

export default Start